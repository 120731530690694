const REGEX_NUMERIC = '^[0-9]+$'
const REGEX_NUMERIC_HYPHEN_SPACE = "^[0-9- ]*$"
const REGEX_ALPHA_NUMERIC = '^[a-zA-Z0-9]+$'
const REGEX_ALPHA_NUMERIC_SPACE ='^[a-zA-Z0-9 ]+$'
const REGEX_ALPHA_NUMERIC_SPACE_DOT ='^[a-zA-Z0-9. ]+$'
const REGEX_ALPHA_NUMERIC_SPEC_CHAR = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s$%^\\[{\\]}\\\\|~]*$"

export {
    REGEX_NUMERIC,
    REGEX_NUMERIC_HYPHEN_SPACE,
    REGEX_ALPHA_NUMERIC,
    REGEX_ALPHA_NUMERIC_SPACE,
    REGEX_ALPHA_NUMERIC_SPACE_DOT,
    REGEX_ALPHA_NUMERIC_SPEC_CHAR
}