const ACI_HWY_MANIFEST = 'aciHwyManifest'
const ACI_HWY_CARGO = 'aciHwyCargo'
const ACE_HWY_MANIFEST = 'aceHwyManifest'
const ACE_HWY_SHIPMENT = 'aceHwyShipment'
const ACE_HWY_MANIFEST_CREW = 'aceHwyManifestCrew'
const ACE_HWY_MANIFEST_PAXLST = 'aceHwyManifestPaxlst'

export {
    ACI_HWY_MANIFEST,
    ACI_HWY_CARGO,
    ACE_HWY_MANIFEST,
    ACE_HWY_SHIPMENT,
    ACE_HWY_MANIFEST_CREW,
    ACE_HWY_MANIFEST_PAXLST
}